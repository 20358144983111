const muiGridTheme = {
  components: {
    MuiGrid: {
      defaultProps: {
        columns: 12,
      },
    },
    MuiGrid2: {
      defaultProps: {
        columns: 12,
      },
    },
  },
};

export default muiGridTheme;
