import {PlacesServiceContext} from 'context/PlacesServiceContext';
import {useEffect, useRef} from 'react';

import {loadGoogleMapScript} from 'utils/maps/loadMaps';

export type TAutocompletePredictions = {
  predictions: TAutocompletePrediction[];
  status: 'OK' | 'Error';
};
export type TAutocompletePrediction = {
  description: string;
  matched_substrings: any;
  place_id: string;
  reference: string;
  structured_formatting: any;
  terms: any;
  types: any;
};
export type TAutocompletePredictionWithLocation =
  | [{disabled: boolean; description: string}, ...TAutocompletePrediction[]]
  | [];
export type TPlacesDetailResponse = {
  lat: number;
  lng: number;
};

export interface IPlacesServiceContext {
  getDetails: (config: {placeId: string}) => Promise<TPlacesDetailResponse>;
  init: () => Promise<void>;
  getPlacePredictions: (config: {
    input: string;
    componentRestrictions: {country: string};
  }) => Promise<TAutocompletePredictions>;
  initialized: boolean;
}

export const GoogleServicesContextProvider = ({children}) => {
  const placesAutocompleteService = useRef(null);
  const placesService = useRef(null);
  const initialized = useRef(false);
  // const {state: consentState, setConsentStateForKey} = useContext(ConsentContext);
  const maps = true;

  useEffect(() => {
    if (maps && !initialized) {
      init();
    }
  }, [maps]);
  const init = () => {
    return loadGoogleMapScript().then(() => {
      if (!initialized.current) {
        // eslint-disable-next-line no-undef
        placesAutocompleteService.current = new (google.maps.places
          .AutocompleteService as unknown as any)(document.createElement('div'));
        // eslint-disable-next-line no-undef
        placesService.current = new google.maps.places.PlacesService(document.createElement('div'));
        initialized.current = true;
      }
    });
  };

  const getPlacePredictions: (config: {
    input: string;
    componentRestrictions: {country: string};
  }) => Promise<TAutocompletePredictions> = config => {
    if (!initialized.current) {
      console.error('Maps not initialized yet');
      return Promise.reject();
    }
    return placesAutocompleteService.current.getPlacePredictions(config);
  };
  const getDetails: (config: {placeId: string}) => Promise<TPlacesDetailResponse> = config =>
    new Promise((resolve, reject) =>
      placesService.current.getDetails({fields: ['geometry.location'], ...config}, ({geometry}) => {
        resolve({
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        });
      }),
    );

  return (
    <PlacesServiceContext.Provider
      value={{getPlacePredictions, getDetails, initialized: initialized.current, init}}
    >
      {children}
    </PlacesServiceContext.Provider>
  );
};
