const muiBackdropTheme = {
  components: {
    MuiBackdrop: {
      styleOverrides: {
        touchAction: "none",

        root: {

          touchAction: "none",

          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};

export default muiBackdropTheme;
