import clsx from 'clsx';
import useCustomController from 'hooks/useCustomController';
import {useFormContext} from 'react-hook-form';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import Typography from '../Typography';
import styles from './styles.module.scss';
import { CustomIcon } from '../CustomIcon/CustomIcon';

export const TimeAdornment = ({name, dateFieldName, onClick = () => {}}) => {
  const {control, watch} = useFormContext();
  const {
    field: {value: fieldValue},
  } = useCustomController({control, name});
  const {isCalendarOpen, selectedTimeField, isWidget} = useStarcarDateRangePickerStateContext();
  const dateFieldValue = watch(dateFieldName);
  if (!dateFieldValue) return null;
  return (
    <button
      onClick={e => {
        e.preventDefault(), onClick();
      }}
      data-id="time-adornment"
      className={clsx({
        [styles.timeAdornmentWrapper]: true,
        [styles.makeClickableInBackdrop]: isCalendarOpen || !!selectedTimeField,
        [styles.timeAdornmentWrapperAddPadding]: !isWidget,
      })}
    >
      <div className={styles.divider}></div>
      <CustomIcon
                name="clockAlt"
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
      <Typography className={styles.timeField}>{fieldValue}</Typography>
    </button>
  );
};
