import clsx from 'clsx';

import MuiBox, { BoxProps } from '@mui/material/Box';

import styles from './scrollBox.module.scss';
import { useEffect, useRef } from 'react';
interface MuiBoxProps extends BoxProps {
  scrollLock?: boolean;
}
const ScrollBox = ({scrollLock, ...props}: MuiBoxProps): JSX.Element => {
  const containerRef = useRef(null);
  useEffect(() => {

    const handleScroll = (e) => {
      const container = containerRef.current;

      // Check if the container is scrolled to the top or bottom
      const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
      const isAtTop = container.scrollTop === 0;

      // Prevent default scrolling if we're at the top or bottom
      if ((isAtBottom && e.deltaY > 0) || (isAtTop && e.deltaY < 0)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const container = containerRef.current;

    if(scrollLock) {
      container.addEventListener('wheel', handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if(scrollLock) {
        container.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);
  
  return <MuiBox ref={containerRef} {...props} className={clsx(styles.scrollBar, props.className)} />;
};

export default ScrollBox;
