import SpinnerContext from 'context/spinnerContext';
// import { withErrorBoundary } from 'components/error/ErrorBoundary';
import {useAddRemoveArray} from 'hooks/useAddRemoveArray';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {v4} from 'uuid';

import {Backdrop} from '@mui/material';

import {
  AXIOS_INSTANCE as AXIOS_BACKEND_INSTANCE, // AxiosRequestConfig,
} from 'lib/api/mutator/custom-instance-backend';

import {Error500Frontend} from 'components/error';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {LoadingCarAnimation} from '../LoadingCarAnimation/LoadingCarAnimation';
import Modal from '../Modal';

// import { Error500Frontend } from 'components/error';

const PageSpinnerWrapper = ({children}) => {
  const {items: requests, addItem: addRequest, removeItem: removeRequest} = useAddRemoveArray();
  const {addItem: addError, clearItems: clearErrors, length: hasError} = useAddRemoveArray();
  const [t] = useTranslation('common');
  const addRequestWithGeneratedId = () => {
    const id = v4();
    addRequest(id);
    return {id, resolve: () => removeRequestWithGeneratedId(id)};
  };
  const removeRequestWithGeneratedId = id => {
    removeRequest(id);
  };
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url, {shallow}) => {
      if (!shallow) {
        if (url.includes('reservation')) {
          addRequest(url, {carSpinner: true});
        } else {
          addRequest(url);
        }
      }
      // console.log(
      //   `App is changing to ${url} ${
      //     shallow ? 'with' : 'without'
      //   } shallow routing`
      // )
    };
    const handleRouteChangeComplete = (url, {shallow}) => {
      if (!shallow) {
        removeRequest(url);
      }
      // console.log(
      //   `App Changed to ${url} ${
      //     shallow ? 'with' : 'without'
      //   } shallow routing`
      // )
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    const requestInterceptor = AXIOS_BACKEND_INSTANCE.interceptors.request.use((config: any) => {
      if (config.noSpinner) {
        return config;
      }
      if (config.carSpinner || requests.filter(({carSpinner}) => carSpinner)) {
        config?.signal?.addEventListener('abort', () => {
          removeRequest(config.requestId);
        });
        addRequest(config.requestId, {carSpinner: true});
      } else {
        config?.signal?.addEventListener('abort', () => {
          removeRequest(config.requestId);
        });
        addRequest(config.requestId, config);
      }
      return config;
    });
    const responseInterceptor = AXIOS_BACKEND_INSTANCE.interceptors.response.use(
      (config: any) => {
        if (config.noSpinner) {
          return config;
        }
        removeRequest(config.requestId);

        return config;
      },
      error => {
        console.log(error, error?.message)
        if (error?.config?.requestId) removeRequest(error.config.requestId);
        if (
          error.response &&
          error?.response?.status &&
          (error?.response?.status === 429 || error?.response?.status >= 500)
        ) {
          removeRequest(error.response.config.requestId);
          if (error?.response?.status >= 500) {
            addError(v4());
          }
        }
        if (error?.message === 'Network Error' && error.config.requestId) {
          addError(v4())
        };

        throw error;
      },
    );
    return () => {
      AXIOS_BACKEND_INSTANCE.interceptors.request.eject(requestInterceptor);
      AXIOS_BACKEND_INSTANCE.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <>
      <Backdrop sx={{zIndex: '9999'}} open={!!requests.length} onClick={function noRefCheck() {}}>
        <LoadingCarAnimation />
        {/* {requests?.find(item => !!item?.carSpinner) ? (
          <LoadingCarAnimation text={t('carSpinnerText')} />
        ) : (
        )} */}
      </Backdrop>
      <Modal open={!!hasError} onClose={() => clearErrors()}>
        <Error500Frontend
          onClick={() => {
            window.location.reload();
            clearErrors();
          }}
        />
      </Modal>
      <SpinnerContext.Provider
        value={{
          addRequestWithGeneratedId,
          removeRequestWithGeneratedId,
        }}
      >
        {children}
      </SpinnerContext.Provider>
    </>
  );
};

//export default PageSpinnerWrapper;
export default withErrorBoundary(PageSpinnerWrapper, 'PageSpinnerWrapper');
