import {createContext} from 'react';



export const SpinnerContext = createContext({
  addRequestWithGeneratedId: () => {},
  removeRequestWithGeneratedId: () => {},
} as {
    addRequestWithGeneratedId: Function;
    removeRequestWithGeneratedId: Function;
});
export default SpinnerContext
