import {createContext} from 'react';

import {MenuComponentsGroupsComponentRenderAs} from 'lib/api/strapi';

export interface LocaleHeaderProps {
  [key: string]: HeaderProps;
}
export interface LocaleNavProps {
  [key: string]: NavProps;
}
export interface HeaderProps {
  headerLinks: NavEntry[];
  headerMenuLoggedInLinks: NavEntryLoggedIn[];
  headerMenuLinks: NavEntry[];
}
export interface LocaleFooterMenuGroup {
  [key: string]: {footerMenuGroups: FooterMenuGroup[]};
}
export interface FooterMenuGroup {
  title: string;
  renderAs: MenuComponentsGroupsComponentRenderAs;
  menuLinks: NavEntry[];
}

export interface LocaleFooterBox {
  [key: string]: {footerBoxes: FooterBox[]};
}

export interface FooterBox {
  id: number;
  title: string;
  link: NavEntry[];
  text: string;
}

export interface LocaleFooterSmallLinks {
  [key: string]: {footerSmallLinks: FooterSmallLink[]};
}

export interface FooterSmallLinks {
  id: number;
  link: FooterSmallLink[];
}

export interface FooterSmallLink {
  id?: number;
  title?: string;
  href?: string;
  newWindow?: boolean;
}

export interface NavEntry {
  id: number;
  title: string;
  href: string;
  bold?: boolean;
  lineTop?: boolean;
  newWindow?: boolean;
}

export interface NavEntryLoggedIn {
  id: number;
  title: string;
  href: string;
  businessClientOnly: boolean;
}

export interface NavProps {
  headerLinks: NavEntry[];
  footerMenuGroups: FooterMenuGroup[];
  footerBoxes: FooterBox[];
  footerSmallLinks: FooterSmallLink[];
  headerMenuLoggedInLinks: NavEntryLoggedIn[];
  headerMenuLinks: NavEntry[];
}

export const NavPropsContext = createContext({
  headerLinks: [],
  footerMenuGroups: [],
  footerBoxes: [],
  footerSmallLinks: [],
  headerMenuLoggedInLinks: [],
  headerMenuLinks: [],
} as NavProps);
