import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React from 'react';

import Container from '@mui/material/Container';
import * as Sentry from '@sentry/react';

import {Box, Button, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';
import FooterSmall from 'components/layout-components/FooterSmall';
import Logo from 'components/layout-components/Navbar/assets/logo_starcar2x.png';

import ErrorImage from './assets/error.png';
import styles from './errorPage.module.scss';

function ErrorComponent({setState}) {
  const {t} = useTranslation('common');
  return (
    <>
      <div style={{backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 1000}}>
        <Container maxWidth={false} disableGutters sx={{maxWidth: '1600px'}}>
          <Box id="headerWrapper" className={styles.wrapper}>
            <Box className={styles.container}>
              <Grid
                alignItems="center"
                container
                direction="row"
                columnSpacing={{xs: 1, sm: 2, md: 4, lg: 8}}
                rowSpacing={8}
              >
                <Grid xs={7} sm={6} md={3} lg={3}>
                  <NextLink href="/">
                    <span style={{cursor: 'pointer'}}>
                      <Box className={styles.imageWrapper}>
                        <Image priority src={Logo} alt={t('logo')} id="logo" />
                      </Box>
                    </span>
                  </NextLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>

      <Container className={styles.containerImage} maxWidth={false} disableGutters>
        <Box className={styles.container}>
          <Box className={styles.errorImageContainer}>
            <Image
              alt=""
              src={ErrorImage}
              className={styles.errorImage}
              objectPosition={'center'}
            ></Image>
          </Box>
        </Box>
      </Container>
      <Container className={styles.containerAction} maxWidth={false} disableGutters>
        <Box className={styles.boxAction}>
          <Button
            type="button"
            variant="inverted"
            size={'small'}
            onClick={() => setState({hasError: false})}
            endIcon={<CustomIcon name="chevronRight" onClick={e => e.preventDefault()} />}
          >
            {t('tryAgain')}
          </Button>
        </Box>

        <FooterSmall />
      </Container>
    </>
  );
}

function ErrorComponentSmall({setState}) {
  const {t} = useTranslation('common');
  return (
    <>
      <Grid container justifyContent={'center'} spacing={0}>
        <Grid className={styles.errorBox}>
          <div className={styles.iconBox}>
            <CustomIcon name="tool" />
          </div>
          <Typography
            sx={{fontSize: {xs: '13px', sm: '16px', md: '24px', xl: '32px'}, fontWeight: '700'}}
          >
            {t('errorText')}
            <br />
            {t('reloadSite')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
interface Props {
  children: React.ReactNode;
  name?: string;
  errorComponent?: any;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: any) {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (this.props.name) {
      console.log('Error Occured in component' + this.props.name);
    }
    Sentry.captureException(error, {extra: errorInfo});
  }

  render() {
    const updateState = state => {
      this.setState(state);
    };
    if (this.state.hasError) {
      if (this.props.errorComponent) {
        const ErrorComponentFromProp = this.props.errorComponent;
        return <ErrorComponentFromProp setState={updateState.bind(this)} />;
      }
      return <ErrorComponent setState={updateState.bind(this)} />;
    }
    return this.props.children;
  }
}
export const withErrorBoundary = <T extends React.ComponentType<any>>(
  OriginalComponent: T,
  name = 'unknown',
) => {
  return function WwithErrorBoundary(props: React.ComponentProps<T>) {
    return (
      <ErrorBoundary name={name || 'unknown'} errorComponent={ErrorComponentSmall}>
        <OriginalComponent {...props} />
      </ErrorBoundary>
    );
  };
};
export default ErrorBoundary;
