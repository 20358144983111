import {Locale, format} from 'date-fns';
import {de, enUS} from 'date-fns/locale';

import {DateFormatter, DateValue} from '@internationalized/date';

export const formatDateString = (date: string, time: string) => {
  if (!date || !time) {
    return '';
  }

  const dateTime = new Date(date);
  const [hours, minutes] = time.split(':').map(Number);

  if (isNaN(dateTime.getTime()) || isNaN(hours) || isNaN(minutes)) {
    return '';
  }

  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);

  const res = `${dateTime.getFullYear()}-${prependZero(dateTime.getMonth() + 1)}-${prependZero(
    dateTime.getDate(),
  )} ${prependZero(dateTime.getHours())}:${prependZero(dateTime.getMinutes())}`;

  return res;
};

const prependZero = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDate = dateString => {
  const res = new Date(dateString).toLocaleDateString('de', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return res;
};

export const formatTime = dateString => {
  return new Date(dateString).toLocaleTimeString('de', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

//return DateString in following Format: day/month
//Example: 03.06.
export const formatDayMonthDate = dateString => {
  return new Date(dateString).toLocaleDateString('de', {
    day: '2-digit',
    month: '2-digit',
  });
};

// returns DateString in following Format: weekday/day/month/year/hour/minute
// Example: Do 05.05.2023 16:00
export const formatFullDate = (dateString, locale = 'de') => {
  let localeObj: Locale;
  switch (locale) {
    case 'en':
      localeObj = enUS;
      break;
    case 'de':
    default:
      localeObj = de;
      break;
  }
  return format(new Date(dateString), 'eeeeee dd.MM.yyyy HH:mm', {
    locale: localeObj,
  });
};

export const formatFullDateWithoutTime = (dateString, locale = 'de') => {
  let localeObj: Locale;
  switch (locale) {
    case 'en':
      localeObj = enUS;
      break;
    case 'de':
    default:
      localeObj = de;
      break;
  }
  return format(new Date(dateString), 'eeeeee. dd.MM.yyyy', {
    locale: localeObj,
  });
};

export const formatWithPattern = (dateString, pattern = 'dd.MM.yyyy HH:mm') => {
  return format(new Date(dateString), pattern, {
    locale: de,
  });
};

//returns DateString in following Format: day/month/hour/minute
//Example: 31.01. 08:00
export const formatMonthYearDate = dateString => {
  return format(new Date(dateString), 'dd.MM. HH:mm', {locale: de});
};

export function formatDateForInput(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

export const transformDateFormat = (date: DateValue, locale) => {
  if (!date) return '';
  const formatter = new DateFormatter(locale, {dateStyle: 'short', timeZone: 'Europe/Berlin'});
  return formatter.format(new Date(date.toString()));
};

export const getDateInBerlinTime = date => {
  // Get the current date and time
  const newDate = new Date(new Date(date).toLocaleString('en-US', {timeZone: 'Europe/Berlin'}));
  return newDate;
};
