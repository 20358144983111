import {forwardRef} from 'react';

import MuiGrid2, {Grid2Props} from '@mui/material/Unstable_Grid2';

interface IProps extends Grid2Props {
  hidden?: boolean;
}
const Grid = ({hidden, ...props}: IProps, ref): JSX.Element => {
  return hidden ? null : <MuiGrid2 {...props} ref={ref} />;
};

export default forwardRef(Grid);
