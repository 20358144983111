import isEqual from 'lodash/isEqual';
import {forwardRef, memo} from 'react';

import MuiTextField from '@mui/material/TextField';

const TextField = (props, ref) => {
  return <MuiTextField {...props} inputRef={ref} />;
};

const MemoizedTextField = memo(forwardRef(TextField), (a, b) => {
  return isEqual(a, b);
});

export type MemoizedTextFieldType = typeof MuiTextField;

export default MemoizedTextField;
