import Link, {LinkProps} from 'next/link';
import {createContext, useContext} from 'react';

export type TNextLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps & {
    children?: React.ReactNode;
  } & React.RefAttributes<HTMLAnchorElement>;
const LinkNestingContext = createContext(false);
export const NextLink = (props: TNextLinkProps) => {
  const nested = useContext(LinkNestingContext);
  if (nested) {
    return props?.children || null;
  }
  if(!props?.href) {
    // ensure no link is rendered without href tag, as it creates an error
    return props?.children || null;
  }
  return (
    <LinkNestingContext.Provider value={true}>
      <Link {...props} />
    </LinkNestingContext.Provider>
  );
};
