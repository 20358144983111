import {createTheme} from '@mui/material/styles';

import {
  muiAccordionTheme,
  muiBackdropTheme,
  muiButtonBaseTheme,
  muiButtonTheme,
  muiCssBaselineTheme,
  muiGridTheme,
  muiInputTheme,
} from './components';
import {breakpoint} from './scss';
import {muiPalette, muiShadows, muiTypography} from './theming';

/**
 * Starcar Theme
 */
declare module '@mui/system' {
  interface BreakpointOverrides {
    // Your custom breakpoints
    xxs: true;
    xxl: true;
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xxs: Number(breakpoint.xxs),
      xs: Number(breakpoint.xs),
      sm: Number(breakpoint.sm),
      md: Number(breakpoint.md),
      lg: Number(breakpoint.lg),
      xl: Number(breakpoint.xl),
      xxl: Number(breakpoint.xxl),
    },
  },

  direction: 'ltr',
  shape: {
    borderRadius: 4,
  },
  spacing: factor => `${4 * factor}px`,
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});

/**
 * Theming
 */
theme = createTheme(theme, muiPalette(theme));
theme = createTheme(theme, muiShadows(theme));
theme = createTheme(theme, muiTypography(theme));

/**
 * Components
 */
theme = createTheme(theme, muiAccordionTheme(theme));
theme = createTheme(theme, muiButtonTheme(theme));
theme = createTheme(theme, muiButtonBaseTheme);
theme = createTheme(theme, muiGridTheme);
theme = createTheme(theme, muiCssBaselineTheme);
theme = createTheme(theme, muiInputTheme(theme));
theme = createTheme(theme, muiBackdropTheme);

export default theme;
