import Image from 'next/image';
import React, {useEffect, useRef, useState} from 'react';

import {Container} from '@mui/material';

import {TypoComponentsContentSectionFramedImageImageDataItem} from 'lib/api/strapi';

import {Box} from 'components/basic-components';

import styles from './Carousel.module.scss';

interface CarouselProps {
  images: TypoComponentsContentSectionFramedImageImageDataItem[];
  autoPlayInterval?: number;
}

export const Carousel: React.FC<CarouselProps> = ({images, autoPlayInterval = 5000}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const thumbnailRef = useRef<HTMLElement>(null);
  const imageRef = useRef<HTMLElement>(null);
  const thumbnailWrapperRef = useRef<HTMLElement>(null);
  const handleSlideChange = (index: number) => {
    setImageIndex(index);
  };

  useEffect(() => {
    if (imageIndex !== null) {
      containerRef.current.scrollTo({
        left: imageIndex * imageRef.current.scrollWidth,
        behavior: 'smooth',
      });
      thumbnailWrapperRef.current.scrollTo({
        left:
          imageIndex * thumbnailRef.current.offsetWidth +
          imageIndex * parseFloat(window.getComputedStyle(thumbnailRef.current, null).marginRight),
        behavior: 'smooth',
      });
    }
  }, [imageIndex]);

  const containerRef = useRef<HTMLElement>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const nextSlide = () => {
      setImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    intervalRef.current = setInterval(nextSlide, autoPlayInterval);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [images.length, autoPlayInterval, imageIndex]);

  const handleMouseDown = (containerRef: React.RefObject<HTMLElement>) => (e: React.MouseEvent) => {
    const ele = containerRef.current as HTMLElement;
    setImageIndex(null);
    if (!ele) {
      return;
    }
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (e: React.MouseEvent) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleMouseUp = () => {
      const index = Math.round((ele.scrollLeft || 1) / ele.children[0].children[0].scrollWidth);
      handleSlideChange(index);
      document.removeEventListener('mousemove', handleMouseMove as any);
      document.removeEventListener('mouseup', handleMouseUp);

      resetCursor(ele);
    };

    document.addEventListener('mousemove', handleMouseMove as any);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart =
    (containerRef: React.RefObject<HTMLElement>) => (e: React.TouchEvent) => {
      const ele = containerRef.current as any;
      setImageIndex(null);

      if (!ele) {
        return;
      }
      const touch = e.touches[0];
      const startPos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        ele.scrollTop = startPos.top - dy;
        ele.scrollLeft = startPos.left - dx;
        updateCursor(ele);
      };

      const handleTouchEnd = () => {
        const index = Math.round((ele.scrollLeft || 1) / ele.children[0].children[0].scrollWidth);
        handleSlideChange(index);
        document.removeEventListener('touchmove', handleTouchMove as any);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor(ele);
      };

      document.addEventListener('touchmove', handleTouchMove as any);
      document.addEventListener('touchend', handleTouchEnd);
    };

  const updateCursor = (ele: HTMLElement) => {
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
  };

  const resetCursor = (ele: HTMLElement) => {
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  };

  return (
    <Container className={styles.wrapper}>
      <Box
        className={styles.imageBox}
        ref={containerRef}
        onMouseDown={handleMouseDown(containerRef)}
        onTouchStart={handleTouchStart(containerRef)}
      >
        <Box
          className={`${styles.imageWrapper}  `}
          sx={{
            height: {xxs: '360px', md: '360px', xxl: '500px'},
          }}
        >
          {images.map((data, index) => (
            <Image
              ref={(index === 0 ? imageRef : null) as any}
              key={data?.id}
              className={styles.imageContainer}
              src={data?.attributes?.url}
              alt={data?.attributes?.alternativeText}
              draggable={false}
              height={data?.attributes?.height}
              width={data?.attributes?.width}
              onClick={() => handleSlideChange(data?.id)}
            />
          ))}
        </Box>
      </Box>

      <Box
        className={styles.thumbnail}
        ref={thumbnailWrapperRef}
        onMouseDown={handleMouseDown(thumbnailWrapperRef)}
        onTouchStart={handleTouchStart(thumbnailWrapperRef)}
      >
        <Box className={styles.inner}>
          {images.map((image, i) => (
            <Image
              ref={(i === 0 ? thumbnailRef : null) as any}
              key={image?.id}
              src={image?.attributes?.url}
              className={styles.thumbnailImage}
              onClick={() => handleSlideChange(i)}
              alt={image?.attributes?.alternativeText}
              width={image?.attributes?.width}
              height={image?.attributes?.height}
              draggable={false}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};
