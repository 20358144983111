import {palette} from '../scss';

const muiInputTheme = theme => {
  return {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
        variants: [
          {
            props: {variant: 'standard'},
            style: {
              color: palette.colorBlack,
              height: '50px',
              '& .MuiInputAdornment-root': {
                color: palette.colorBlack,
                height: 'auto',
                marginRight: '6px',
              },
              '& .MuiInputLabel-root': {
                lineHeight: '1.5rem',
                color: palette.colorGrey,
                zIndex: 1,
                pointerEvents: 'none',
                cursor: 'text',
                '&.Mui-error': {
                  color: 'inherit',
                },
                '&.Mui-focused': {
                  color: palette.colorGrey60,
                },
              },
              '& .MuiInput-root': {
                '& .MuiInput-input': {
                  paddingBottom: '6px',
                  height: '1.5rem',
                  fontWeight: '700',
                  color: palette.colorGrey80,
                  '&:-webkit-autofill': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:hover': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:focus': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:active': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&.Mui-disabled': {
                    textFillColor: palette.colorGrey20,
                  },
                  '&:focus': {
                    backgroundColor: 'inherit',
                  },
                },
                // '&:hover': {
                //   '&:before': {
                //     borderBottom: '1px solid #ababab',
                //   },
                // },
                '&.Mui-focused:before': {
                  borderBottom: `2px solid ${palette.colorFocus}`,
                },
                '&:before': {
                  borderBottom: '1px solid #ababab',
                },
                '&.Mui-error:before': {
                  borderBottom: `1px solid ${palette.colorError}`,
                },
                '&:after': {
                  borderBottom: `1px solid ${palette.colorGrey}`,
                },
              },
              '& .MuiInputLabel-shrink': {
                color: palette.colorGrey60,
                fontSize: '12px !important',
                '&.Mui-disabled': {
                  color: palette.colorGrey60,
                },
              },
              '&.Mui-disabled': {
                color: palette.colorGrey40,
                '&:before': {
                  borderBottomStyle: 'solid',
                  borderColor: palette.colorGrey40,
                },
              },
              '&:after': {
                borderWidth: '1px',
              },

              /* Webkit / Chrome */
              '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
              },
              /* Firefox */
              '& input[type=number]': {
                appearance: 'textfield',
              },
            },
          },
          {
            props: {variant: 'filled'},
            style: {
              '& textarea': {
                padding: '0 !important',
              },
              '& .MuiInputBase-adornedStart': {
                paddingLeft: '10px',
              },
              '& .MuiFilledInput-root': {
                backgroundColor: palette.colorWhite,
                borderBottomLeftRadius: '2px',
                borderBottomRightRadius: '2px',
                borderTopLeftRadius: '2px',
                borderTopRightRadius: '2px',
                '& .MuiInputAdornment-root': {
                  marginTop: '26px !important',
                  marginBottom: '10px',
                  marginRight: '5px',
                  height: 'auto',
                  color: palette.colorBlack,
                },
                '& .MuiInputAdornment-root + .MuiFilledInput-input': {
                  padding: '26px 10px 10px 0',
                },
                '&:hover:not(.Mui-disabled)': {
                  backgroundColor: palette.colorWhite,
                },
                '&:active:not(.Mui-disabled)': {
                  outline: `1px solid ${palette.colorGrey60}`,
                  backgroundColor: palette.colorWhite,
                },
                '&.Mui-focused': {
                  outline: `2px solid ${palette.colorFocus}`,
                  backgroundColor: palette.colorWhite,
                },
                '&.Mui-error': {
                  outline: `2px solid ${palette.colorError}`,
                },
                '&.Mui-disabled': {
                  backgroundColor: '#f2f2f2',
                },
                '&::after': {
                  display: 'none',
                },
                '&::before': {
                  display: 'none',
                },
                '& .MuiFilledInput-input': {
                  padding: '26px 10px 10px 10px',
                  height: '1.5rem',
                  fontWeight: '700',
                  color: palette.colorGrey80,
                  '&:-webkit-autofill': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:hover': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:focus': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&:-webkit-autofill:active': {
                    transition: 'background-color 600000s 0s, color 600000s 0s',
                  },
                  '&.Mui-disabled': {
                    textFillColor: palette.colorGrey80,
                  },
                },
              },
              '& .MuiInputLabel-root[data-shrink="false"] + .MuiInputBase-formControl .MuiFilledInput-input':
                {
                  padding: '18px 10px',
                },
              '& .MuiInputLabel-root': {
                color: palette.colorGrey20,
                lineHeight: '1.5rem',
                marginLeft: '-2px',
                marginTop: '2px',
                zIndex: 1,
                cursor: 'text',
                '&.Mui-focused': {
                  color: palette.colorGrey60,
                },
                '&.Mui-error': {
                  color: palette.colorGrey60,
                },
              },
              '& .MuiInputLabel-shrink': {
                color: palette.colorGrey60,
                fontSize: '12px !important',
                height: '18px',
                marginTop: 0,
                '&.Mui-disabled': {
                  color: palette.colorGrey60,
                },
              },
              /* Webkit / Chrome */
              '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
              },
              /* Firefox */
              '& input[type=number]': {
                appearance: 'textfield',
              },
            },
          },
        ],
      },
    },
  };
};

export default muiInputTheme;
