import {useCalendarGrid} from 'react-aria';
import {RangeCalendarState} from 'react-stately';

import {DateValue, endOfMonth, getWeeksInMonth, parseDate} from '@internationalized/date';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

import {palette} from 'styles/mui/scss';

import {CalendarCell} from './CalendarCell';
import styles from './styles.module.scss';

// Make sure CalendarCell is adapted to Material-UI

export function CalendarGrid({
  state,
  offset = {},
  minValue,
  maxValue,
}: {
  state: RangeCalendarState;
  offset?: {months?: number};
  minValue: DateValue;
  maxValue: DateValue;
}) {
  let startDate = state.visibleRange.start.add(offset);
  let endDate = parseDate(endOfMonth(startDate).toString());
  let {gridProps, headerProps, weekDays} = useCalendarGrid(
    {
      weekdayStyle: 'short',
      startDate,
      endDate,
    },
    state,
  );
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, 'de-DE');
  
  return (
    <Table className={styles.calendar} {...gridProps}>
      <TableHead {...headerProps}>
        <TableRow>
          {weekDays.map((day, index) => (
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '14px',
                lineHeight: '20px',
                color: palette.colorGrey60,
                padding: '14px 12px',
                height: '48px',
                textAlign: 'center',
              }}
              key={index}
            >
              {day}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...new Array(weeksInMonth).keys()].map(weekIndex => (
          <TableRow key={weekIndex} sx={{outline: 'none', border: '0px solid black !important'}}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                    disabled={
                      date.compare(minValue) < 0 || (maxValue && date.compare(maxValue) > 0)
                    }
                  />
                ) : (
                  <TableCell key={i} />
                ),
              )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
