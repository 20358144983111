import { useState } from "react";

export const useAddRemoveArray = () => {
    const [items, setItems] = useState([]);
    const addItem = (requestId, additionalProps = {}) => setItems([...items, {id: requestId, ...additionalProps}]);
    const clearItems = () => setItems([]);
    const removeItem = requestId => setItems(items.filter(({id}) => id !== requestId));
    // if(items.length)  console.log("requestItems", {items});
    return {
        items,
        addItem,
        removeItem,
        setItems,
        clearItems,
        length: items.length
    }
}
