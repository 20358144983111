import {NavPropsContext} from 'context/navPropsContext';
import {useContext} from 'react';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';

import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './FooterSmall.module.scss';
import {FacebookLogo, InstagramLogo, TikTokLogo} from './SocialMediaIcons';

const FooterSmall = () => {
  const navProps = useContext(NavPropsContext);
  const footerSmallLinks = navProps?.footerSmallLinks || [];
  return (
    <ContentWrapper sx={{backgroundColor: palette.colorDarkGrey}}>
      <Box className={styles.wrapper}>
        <Box className={styles.footerSmallLinkWrapper}>
          {footerSmallLinks?.map(link => {
            return (
              <Typography key={link?.id} variant="footerLabeling">
                <NextLink
                  target={link?.newWindow ? '_blank' : '_self'}
                  className={styles.footerSmallLink}
                  href={link?.href}
                >
                  {link?.title}
                </NextLink>
              </Typography>
            );
          })}
        </Box>
        <Box className={styles.socialMediaWrapper}>
          <NextLink
            aria-label="Starcar Facebook"
            target="_blank"
            className={styles.logo}
            href={'https://www.facebook.com/Starcar.Autovermietung'}
          >
            <FacebookLogo className={styles.logo} />
          </NextLink>
          <NextLink
            aria-label="Starcar Instagram"
            target="_blank"
            className={styles.logo}
            href={'https://www.instagram.com/starcar_autovermietung/'}
          >
            <InstagramLogo className={styles.logo} />
          </NextLink>
          <NextLink
            aria-label="Starcar TikTok"
            target="_blank"
            className={styles.logo}
            href={'https://www.tiktok.com/@starcar_autovermietung'}
          >
            <TikTokLogo className={styles.logo} />
          </NextLink>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default FooterSmall;
