import {palette} from '../scss';
const muiPalette = theme => {
  return {
    palette: {
      primary: {
        main: palette.colorPrimary,
      },
      secondary: {
        main: palette.colorPrimary,
      },
      error: {
        main: palette.colorError,
      },
    },
  };
};

export default muiPalette;
