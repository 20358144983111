import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import {MouseEventHandler, useState} from 'react';

import {Box, Button, Grid, Typography} from 'components/basic-components';

import ErrorImage from './assets/error.png';
import styles from './errorPage.module.scss';

interface IError500FrontendProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Error500Frontend: React.FC<IError500FrontendProps> = ({onClick}) => {
  const {t} = useTranslation('common');
  const [hasImageError, setImageError] = useState(false);
  return (
    <Grid
      container
      flexDirection="column"
      alignContent={'center'}
      className={styles.errorPopupGrid}
    >
      <Box className={styles.errorPopupWrapper}>
        {!hasImageError ? <Image
          alt="Error image"
          src={ErrorImage}
          className={styles.errorImage}
          objectPosition={'center'}
          height={370}
          width={500}
          onError={() => {
            setImageError(true);
          }}
        ></Image> : <Typography variant='h2'>{t('oopsProblem')}</Typography>}
      </Box>
      <Button className={styles.errorPopupButton} variant="inverted" onClick={onClick}>
        {t('reloadPage')}
      </Button>
    </Grid>
  );
};
