import React from 'react';

import {Container} from '@mui/material';
import {BoxProps} from '@mui/material/Box';

import {Box} from 'components/basic-components';

import styles from './ContentWrapper.module.scss';

interface ContentWrapperProps extends BoxProps {
  children: React.ReactElement | React.ReactElement[];
  fullWidth?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  fullWidth = false,
  sx,
  ...rest
}) => {
  return (
    <Box sx={sx} {...rest}>
      <Container
        maxWidth={false}
        disableGutters
        className={fullWidth ? styles.fullWidth : styles.maxWidth}
      >
        {children}
      </Container>
    </Box>
  );
};
export default ContentWrapper;
