import {useRouter} from 'next/router';

interface IgetLocalePrefixProps {
  locale: string;
  defaultLocale: string;
  slash?: ISlashEnum;
}
interface IuseLocalePrefix {
  slash?: ISlashEnum;
}
type ISlashEnum = 'none' | 'front' | 'back' | 'back-front';
export const getLocalePrefix = ({locale, defaultLocale, slash = 'back'}: IgetLocalePrefixProps) => {
  if (locale !== defaultLocale) {
    switch (slash) {
      case 'none':
        return `${locale}`;
      case 'front':
        return `/${locale}`;
      case 'back':
        return `${locale}/`;
      case 'back-front':
        return `/${locale}/`;
      default:
        return `${locale}/`;
    }
  } else {
    return '';
  }
};
export const useLocaleprefix = ({slash = 'back'}: IuseLocalePrefix): string => {
  const {locale, defaultLocale} = useRouter();
  return getLocalePrefix({locale, defaultLocale, slash});
};
