import {FC, SVGProps} from 'react';

import icons from './icons';

export type TIconTypes = keyof typeof icons;
export const iconTypes = Object.keys(icons);

export interface ICustomIconProps extends SVGProps<SVGElement> {
  name: TIconTypes;
}
// Icon Component
export const CustomIcon: FC<SVGProps<SVGElement>> = ({name, ...props}: ICustomIconProps) => {
  let Icon;
  if (icons[name]) {
    Icon = icons[name];
    return <Icon {...props} />;
  }
  return <span>no Icon</span>;
};
