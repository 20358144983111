import {default as KLMSplitterIcon} from './KLM_location.svg';
import {default as KLMSplitterMobileIcon} from './KLM_location_mobile.svg';
import {default as ArrowIcon} from './arrow.svg';
import {default as ArrowAltIcon} from './arrowAlt.svg';
import {default as BarsIcon} from './barsSort.svg';
import {default as BillIcon} from './bill.svg';
import {default as BulbIcon} from './bulb.svg';
import {default as CakeIcon} from './cake.svg';
import {default as CalendarIcon} from './calendar.svg';
import {default as CarIcon} from './car-icon.svg';
import {default as CelebrationIcon} from './celebration.svg';
import {default as Checkbox} from './checkbox.svg';
import {default as CheckmarkIcon} from './checkmarkIcon.svg';
import {default as ChevronDown} from './chevronDown.svg';
import {default as ChevronLeft} from './chevronLeft.svg';
import {default as ChevronRight} from './chevronRight.svg';
import {default as ChevronUp} from './chevronUp.svg';
import {default as ClockIcon} from './clock.svg';
import {default as ClockAltIcon} from './clockAlt.svg';
import {default as CloseIcon} from './close.svg';
import {default as CreditcardIcon} from './creditcard.svg';
import {default as DiversityIcon} from './diversity.svg';
import {default as DocumentIcon} from './document.svg';
import {default as DoorIcon} from './door.svg';
import {default as DownloadIcon} from './download.svg';
import {default as DrivingExperience} from './drivingExperience.svg';
import {default as DrivingLicenseIcon} from './drivingLicense.svg';
import {default as ElectroIcon} from './electro.svg';
import {default as HeartIcon} from './favheart.svg';
import {default as FileIcon} from './file.svg';
import {default as FilterIcon} from './filter.svg';
import {default as GasPumpIcon} from './gasPump.svg';
import {default as GlobeIcon} from './globe.svg';
import {default as ToolIccon} from './group-7.svg';
import {default as InfoIcon} from './info.svg';
import {default as KeyIcon} from './key.svg';
import {default as KeyAltIcon} from './keyAlt.svg';
import {default as LiftingPlatform} from './liftingPlatform.svg';
import {default as LightningIcon} from './lightning.svg';
import {default as LoginIcon} from './loginIcon.svg';
import {default as MagnifyingGlassIcon} from './magnifyingGlass.svg';
import {default as MapMarker} from './mapMarker.svg';
import {default as MegaphoneIcon} from './megaphone.svg';
import {default as MenuIcon} from './menuIcon.svg';
import {default as MoonIcon} from './moon.svg';
import {default as OfferIcon} from './offer.svg';
import {default as PdfIcon} from './pdf.svg';
import {default as PencilIcon} from './pencil.svg';
import {default as PeopleIcon} from './people.svg';
import {default as PlaneIcon} from './plane.svg';
import {default as PricetagIcon} from './pricetag.svg';
import {default as SliderArrow} from './sliderArrow.svg';
import {default as StarIcon} from './star.svg';
import {default as StarAltIcon} from './starAlt.svg';
import {default as StationIcon} from './station.svg';
import {default as TachometerIcon} from './tachometer.svg';
import {default as TeamIcon} from './team.svg';
import {default as TimeSlotIcon} from './timeSlot.svg';
import {default as TrainIcon} from './train.svg';
import {default as TruckIcon} from './truck-icon.svg';
import {default as UpdateIcon} from './update.svg';
import {default as WeightIcon} from './weight.svg';

export {
  ArrowIcon,
  BarsIcon,
  BillIcon,
  BulbIcon,
  CarIcon,
  CelebrationIcon,
  Checkbox,
  CheckmarkIcon,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  CloseIcon,
  CreditcardIcon,
  DiversityIcon,
  DocumentIcon,
  DoorIcon,
  DownloadIcon,
  DrivingLicenseIcon,
  ElectroIcon,
  HeartIcon,
  FilterIcon,
  KeyIcon,
  LoginIcon,
  MagnifyingGlassIcon,
  MapMarker,
  MegaphoneIcon,
  MenuIcon,
  OfferIcon,
  PencilIcon,
  PeopleIcon,
  PricetagIcon,
  SliderArrow,
  StarIcon,
  StationIcon,
  TeamIcon,
  TruckIcon,
  UpdateIcon,
  WeightIcon,
  PlaneIcon,
  TrainIcon,
  MoonIcon,
  LightningIcon,
  KeyAltIcon,
  StarAltIcon,
  ClockIcon,
  TimeSlotIcon,
  GasPumpIcon,
  KLMSplitterIcon,
  KLMSplitterMobileIcon,
  GlobeIcon,
  ArrowAltIcon,
  InfoIcon,
  DrivingExperience,
  CakeIcon,
  FileIcon,
  PdfIcon,
  LiftingPlatform,
  ClockAltIcon,
  ToolIccon,
};

const defaultExport = {
  login: LoginIcon,
  menu: MenuIcon,
  station: StationIcon,
  arrow: ArrowIcon,
  checkmark: CheckmarkIcon,
  mapMarker: MapMarker,
  document: DocumentIcon,
  sliderArrow: SliderArrow,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  calendar: CalendarIcon,
  tachometer: TachometerIcon,
  people: PeopleIcon,
  star: StarIcon,
  electro: ElectroIcon,
  door: DoorIcon,
  weight: WeightIcon,
  drivingLicense: DrivingLicenseIcon,
  drivingExperience: DrivingExperience,
  pricetag: PricetagIcon,
  creditcard: CreditcardIcon,
  heart: HeartIcon,
  megaphone: MegaphoneIcon,
  team: TeamIcon,
  bulb: BulbIcon,
  celebration: CelebrationIcon,
  diversity: DiversityIcon,
  offer: OfferIcon,
  update: UpdateIcon,
  bars: BarsIcon,
  filter: FilterIcon,
  close: CloseIcon,
  car: CarIcon,
  truck: TruckIcon,
  checkbox: Checkbox,
  download: DownloadIcon,
  magnifyingglass: MagnifyingGlassIcon,
  key: KeyIcon,
  pencil: PencilIcon,
  bill: BillIcon,
  plane: PlaneIcon,
  train: TrainIcon,
  moon: MoonIcon,
  lightning: LightningIcon,
  keyAlt: KeyAltIcon,
  starAlt: StarAltIcon,
  clock: ClockIcon,
  timeSlot: TimeSlotIcon,
  gasPump: GasPumpIcon,
  klmIcon: KLMSplitterIcon,
  klmIconMobile: KLMSplitterMobileIcon,
  globe: GlobeIcon,
  arrowAlt: ArrowAltIcon,
  info: InfoIcon,
  cake: CakeIcon,
  file: FileIcon,
  pdf: PdfIcon,
  liftingPlatform: LiftingPlatform,
  clockAlt: ClockAltIcon,
  tool: ToolIccon,
};
export default defaultExport;
