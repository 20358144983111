import ScrollBox from '../ScrollBox';
import {TimeSlotCategories} from './TimeSlotCategories';
import styles from './styles.module.scss';

export const TimeSelectFlyout = () => {
  return (
    <ScrollBox scrollLock className={styles.timeWrapper}>
      <TimeSlotCategories wrapperClassName={styles.timeWrapper} />
    </ScrollBox>
  );
};
