import clsx from 'clsx';
import {forwardRef} from 'react';

import {Box} from '@mui/material';

import styles from './styles.module.scss';
import { useStarcarDateRangePickerStateContext } from 'hooks/BPR/useStarcarDateRangePickerState';

const DayCellComponent = (
  {children, hidden, isUnavailable, isStart, isEnd, selected, ...props},
  ref,
) => {
  const {isSelecting, isDesktop} = useStarcarDateRangePickerStateContext();
  return (
    <Box
      {...props}
      className={clsx({
        [styles.dayCell]: true,
        [styles.selected]: selected && (isDesktop || !isSelecting || isSelecting && !isDesktop  && (isStart)), 
        [styles.isStart]: isStart,
        [styles.isEnd]: isEnd,
        [styles.isUnavailable]: isUnavailable,
        [styles.hidden]: hidden,
      })}
      ref={ref}
    >
      <div className={`${styles.innerCell}`}>{children}</div>
    </Box>
  );
};
export const DayCell = forwardRef(DayCellComponent);
