import {NEXT_PUBLIC_BASE_URL} from 'config/index';
import {GoogleServicesContextProvider} from 'context/GoogleServicesContextProvider';
import {useClearSessionStorageOnLoginLogout} from 'hooks/useClearSessionStorageOnLoginLogout';
import {getLocalePrefix} from 'hooks/useLocalePrefix';
import {useUpdateTranslations} from 'hooks/useUpdateTranslations';
import {SessionProvider} from 'next-auth/react';
import {appWithTranslation} from 'next-i18next';
import type {AppProps} from 'next/app';
import dynamic from 'next/dynamic';
import {Inter} from 'next/font/google';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query';

import {AppCacheProvider} from '@mui/material-nextjs/v13-pagesRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';

import {AXIOS_INSTANCE} from 'lib/api/mutator/custom-instance-backend';

import PageSpinnerWrapper from 'components/basic-components/PageSpinnerWrapper';
import ErrorBoundary from 'components/error/ErrorBoundary';
import logo from 'components/layout-components/Navbar/assets/logo_starcar2x.png';

import theme from '../styles/mui/muiTheme';
import './_app.scss';

const inter = Inter({subsets: ['latin'], weight: ['400', '700', '900'], variable: '--font-inter'});

const EnvironmentSwitch = dynamic(
  () => import('components/layout-components/EnvironmentSwitch/EnvironmentSwitch'),
  {ssr: false},
);

function App({Component, pageProps, ...props}: AppProps) {
  const router = useRouter();
  const [queryClient] = React.useState(() => new QueryClient());
  const session = pageProps.session;
  useUpdateTranslations();
  const currentLocale = pageProps?.page?.locale;
  const defaultLocale = router.defaultLocale;

  const structuredDataOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'STAR CAR GmbH Kraftfahrzeugvermietung',
    url: `${NEXT_PUBLIC_BASE_URL}/${getLocalePrefix({
      locale: currentLocale,
      defaultLocale,
      slash: 'back',
    })}`,
    logo: `${NEXT_PUBLIC_BASE_URL}${logo.src}`,
    sameAs: [
      'https://www.facebook.com/Starcar.Autovermietung',
      'https://www.instagram.com/starcar_autovermietung/',
      'https://www.youtube.com/channel/UCRTd_toV7nENPqN2FfWdRKQ',
    ],
  };
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  useEffect(() => {
    // This will only run on the client
    if (typeof window !== 'undefined') {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      setScrollbarWidth(scrollbarWidth);

      // Set the CSS variable for scrollbar width
      document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    }
  }, []);
  AXIOS_INSTANCE.defaults.headers['Accept-Language'] = router.locale;
  AXIOS_INSTANCE.defaults.headers['X-Frontend-App'] = 'next';
  return (
    <AppCacheProvider {...props}>
      <SessionProvider session={session}>
        <style jsx global>{`
          :root {
            --font-inter: ${inter.style.fontFamily};
            --scrollbar-width: ${scrollbarWidth}px}
          }
        `}</style>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <Head>
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(structuredDataOrganization)}}
              ></script>
            </Head>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <PageSpinnerWrapper>
                <GoogleServicesContextProvider>
                  <ErrorBoundary>
                    <SessionClear />
                    <Component {...pageProps} />
                    {process.env.NEXT_PUBLIC_STARCAR_COOKIE_DOMAIN ? <EnvironmentSwitch /> : null}
                  </ErrorBoundary>
                </GoogleServicesContextProvider>
              </PageSpinnerWrapper>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </AppCacheProvider>
  );
}

const SessionClear = () => {
  useClearSessionStorageOnLoginLogout();
  return null;
};

export default appWithTranslation(App);
