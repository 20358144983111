import useCustomController from 'hooks/useCustomController';
import {ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';

import {StandardTextFieldProps} from '@mui/material';

import TextField from '../TextField';

interface FormInputDateProps extends Omit<StandardTextFieldProps, 'variant'> {
  name: string;
  label: ReactNode;
  placeholder: string;
  variant?: 'standard' | 'filled';
}

export const FormInputTime = ({name, label, placeholder, variant, ...rest}: FormInputDateProps) => {
  const {control} = useFormContext();
  const {
    fieldState: {error},
    field: {value, onBlur},
  } = useCustomController({control, name});

  return (
    <TextField
      sx={{width: '100%'}}
      variant={variant}
      error={Boolean(error?.message)}
      helperText={error?.message}
      label={label}
      placeholder={placeholder}
      {...rest}
      value={value}
      onChange={() => {}}
      onBlur={onBlur}
      name={name}
    />
  );
};
