import clsx from 'clsx';

import {Box} from '@mui/material';
import MuiButton, {ButtonProps} from '@mui/material/Button';

import {palette} from '../../../styles/mui/scss';
import {Progress} from '../Progress';

export interface ExtendedButtonProps extends ButtonProps {
  loading?: boolean;
}

/** Mui Button Component. For full prop information see here: https://mui.com/material-ui/react-button/  */
export const Button: React.FC<ExtendedButtonProps> = ({loading, children, ...buttonProps}) => {
  const buttonClasses = clsx('MuiButton-root', {
    'Mui-loading': loading,
  });
  return (
    <MuiButton className={buttonClasses} {...buttonProps}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '38px',
          }}
        >
          <Progress
            width={'38'}
            strokeColor={
              buttonProps.variant === 'inverted' ? palette.colorPrimary : palette.colorBlack
            }
          />
        </Box>
      )}
      <div className="buttonText">{children}</div>
    </MuiButton>
  );
};
