import usePrevious from 'hooks/usePrevious';
import {useSession} from 'next-auth/react';
import {useEffect} from 'react';

export const useClearSessionStorageOnLoginLogout = () => {
  const session = useSession();
  const previousLoginState = usePrevious(session?.status, x => x !== 'loading' && !!x);
  useEffect(() => {
    if (session?.status === 'unauthenticated' && previousLoginState === 'authenticated') {
      window?.sessionStorage?.clear();
    }
  }, [session.status, previousLoginState]);
};
