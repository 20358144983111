import React from 'react';

// import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';

// import {ErrorErrorComponent} from 'lib/api/strapi';

// import {Grid} from 'components/basic-components';
// import {Image} from 'components/basic-components/StrapiImage';

// import ErrorLinks from './ErrorLinks';
// import styles from './errorPage.module.scss';

const ErrorPageComponent = ({errorContent, image, fullWidthImage}) => {
  if (!errorContent || errorContent?.length !== 4) {
    return <div>ein unbekannter fehler ist aufgetreten</div>;
  }
  return (
    <>
      {/* <DefaultWrapper variant="noPadding" fullWidth={fullWidthImage}>
        <Image alt="404 Fehler " image={image?.data?.attributes} />
      </DefaultWrapper>

      <DefaultWrapper variant="paddingLeftAndRight" className={styles.wrapper}>
        <Divider />

        <Grid container justifyContent="space-between">
          <Box className={styles.errorContentContainer}>
            <ErrorLinks textItem={errorContent[0]} />
            <Divider />
            <ErrorLinks textItem={errorContent[1]} />
          </Box>
          <Box className={styles.errorContentContainer}>
            <Divider className={styles.errorSmallScreenDivider} />
            <ErrorLinks textItem={errorContent[2]} />
            <Divider />
            <ErrorLinks textItem={errorContent[3]} />
          </Box>
        </Grid>
      </DefaultWrapper> */}
    </>
  );
};

export default ErrorPageComponent;
