import FormFieldNamesContext from 'context/FormFieldNamesContext';
import {useContext, useEffect} from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
  useController,
} from 'react-hook-form';

export default function useCustomController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName>): UseControllerReturn<TFieldValues, TName> {
  const {name} = props;
  const {registerFormFieldName, unregisterFormFieldName} = useContext(FormFieldNamesContext);
  const controllerResponse = useController(props);
  controllerResponse.field.value = controllerResponse.field.value || ('' as any);
  useEffect(() => {
    registerFormFieldName(name);
    return () => unregisterFormFieldName(name);
  }, []);
  return controllerResponse;
}
