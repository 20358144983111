import {typography} from '../scss';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Small: true;
    titleMobile: true;
    teaser: true;
    footerCards: true;
    buttonCards: true;
    formInput: true;
    menuLabel: true;
    footerLabeling: true;
    chip: true;
    formLabeling: true;
  }
}

const muiTypography = theme => {
  return {
    typography: {
      fontFamily: typography.fontFamilyDefault,
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      fontWeightBlack: 900,
      h1: {
        fontSize: theme.typography.pxToRem(42),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: 900,
        // [theme.breakpoints.down('md')]: {
        //   fontSize: theme.typography.pxToRem(36),
        // },
        // [theme.breakpoints.down('sm')]: {
        //   fontSize: theme.typography.pxToRem(26),
        // },
      },
      h1Small: {
        fontSize: theme.typography.pxToRem(32),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      titleMobile: {
        fontSize: theme.typography.pxToRem(26),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: 900,
      },
      teaser: {
        fontSize: theme.typography.pxToRem(24),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: 900,
      },
      h2: {
        fontSize: theme.typography.pxToRem(24),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      footerCards: {
        fontSize: theme.typography.pxToRem(20),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      buttonCards: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'unset',
      },
      formInput: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      menuLabel: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      body1: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightRegular,
      },
      footerLabeling: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      chip: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        component: 'p',
      },
      formLabeling: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: typography.fontFamilyDefault,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
  };
};

export default muiTypography;
