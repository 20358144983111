import Axios, {AxiosError, AxiosRequestConfig as old} from 'axios';
import {NEXT_PUBLIC_BACKEND_URL} from 'config/index';
import {signOut} from 'next-auth/react';
// import { NEXT_PUBLIC_BACKEND_URL } from 'utils/config'
import {v4 as uuidv4} from 'uuid';

export interface AxiosRequestConfig extends old {
  [x: string]: any;
  requestId?: string;
  noSpinner?: boolean;
}

export const AXIOS_INSTANCE = Axios.create({baseURL: NEXT_PUBLIC_BACKEND_URL}); // use your own URL here or environment variable
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const basicAuthConfig =
    process.env.NEXT_PUBLIC_BASIC_AUTH_USER && process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD
      ? {
          auth: {
            username: process.env.NEXT_PUBLIC_BASIC_AUTH_USER,
            password: process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD,
          },
        }
      : {};
  const promise = AXIOS_INSTANCE({
    withCredentials: true,
    ...basicAuthConfig,
    ...config,
    ...options,
    signal: config?.signal || options?.signal,
    // cancelToken: source.token,
    requestId: uuidv4(),
  } as AxiosRequestConfig)
    .then(({data} = {} as any) => data)
    .catch(err => {
      if (err.message === 'canceled') {
        // console.log(err)
      } else {
        if (
          err?.response?.data &&
          err.response.data?.code === 401 &&
          err.response.data?.message === 'Expired JWT Token'
        ) {
          signOut({redirect: true});
        }
        if (
          err?.response?.data?.errors?.service?.length &&
          err?.response?.data?.errors?.service?.[0].includes('emergency') &&
          typeof window !== 'undefined' &&
          window?.refetchEnvironment
        ) {
          (window as any).refetchEnvironment();
        }
        console.log("request error on", {url: config.url, code: err?.response?.data?.code})
        throw err;
      }
    });

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;
