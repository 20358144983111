const muiCssBaselineTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        button: {
          color: 'inherit'
        }
      }
    },
  },
};

export default muiCssBaselineTheme;
