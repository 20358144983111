import { useEffect, useRef} from 'react';

export default function usePrevious<T, X>(value: T, checkFunction = x => true) {
  const ref = useRef<T>();
  useEffect(() => {
    if (checkFunction(value)) {
      ref.current = value;
    }
  });
  return ref.current as T;
}
