import {useContext, useRef} from 'react';
import {useCalendarCell} from 'react-aria';

import {isSameMonth} from '@internationalized/date';
import {TableCell} from '@mui/material';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import {CalendarContext} from './CalendarContext';
import {DayCell} from './DayCell';

export function CalendarCell({state, date, currentMonth, disabled}) {
  let ref = useRef();
  const {cellClickHandler} = useContext(CalendarContext);
  const {maxRentalDuration} = useStarcarDateRangePickerStateContext();
  let {cellProps, buttonProps, isSelected, formattedDate, isUnavailable} = useCalendarCell(
    {date},
    state,
    ref,
  );
  const isSelectedByRange = isUnavailable
    ? state?.highlightedRange?.end?.compare(date) >= 0 &&
      state?.highlightedRange?.start?.compare(date) <= 0
    : false;
  const isStart = state?.highlightedRange?.start?.compare(date) === 0;
  const isEnd = state?.highlightedRange?.end?.compare(date) === 0;
  let isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <TableCell
      {...cellProps}
      padding={'none'}
      sx={{
        lineHeight: 'initial',
        borderBottom: 'none',
        fontWeight: maxRentalDuration && !disabled ? 'bold' : undefined,
      }}
      aria-selected={cellProps['aria-selected'] || isSelectedByRange}
      aria-disabled={isUnavailable || isOutsideMonth || disabled}
      align="center"
    >
      <DayCell
        {...buttonProps}
        onClick={e => {
          e.preventDefault();
          console.log('Triggered');
          buttonProps?.onClick(e);
          if (!(isUnavailable || isOutsideMonth || disabled)) {
            cellClickHandler(date);
          }
        }}
        data-testid={!isOutsideMonth ? `date-cell-${date.toString()}` : undefined}
        ref={ref}
        hidden={isOutsideMonth}
        selected={isSelected || isSelectedByRange}
        isStart={isStart}
        isEnd={isEnd}
        isUnavailable={isUnavailable || isOutsideMonth || disabled}
        aria-selected={buttonProps['aria-selected'] || isSelectedByRange}
      >
        {formattedDate}
      </DayCell>
    </TableCell>
  );
}
