import React from 'react';
import dynamic from 'next/dynamic';
const RotatingLines = dynamic(() => import('components/basic-components/RotatingLines'));

import {palette} from '../../../styles/mui/scss';

export const Progress = ({
  width = '180',
  strokeWidth = '3',
  strokeColor = `${palette.colorPrimary}`,
}: {
  width?: string;
  strokeWidth?: string;
  strokeColor?: string;
}): JSX.Element => {
  return <RotatingLines width={width} strokeWidth={strokeWidth} strokeColor={strokeColor} />;
};
