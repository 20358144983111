import {palette} from '../scss';
import {typography} from '../scss';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inverted: true;
    secondary: true;
  }
}

const muiButtonTheme = theme => {
  return {
    components: {
      MuiButton: {
        variants: [
          {
            props: {variant: 'inverted'},
            style: {
              backgroundColor: palette.colorBlack,
              color: palette.colorPrimary,
              '&:hover': {
                backgroundColor: '#3c3c3c',
              },
              '&.Mui-disabled': {
                backgroundColor: palette.colorBlack,
                color: palette.colorWhite,
                opacity: 0.3,
                '& svg': {
                  color: palette.colorWhite,
                },
              },
              '&:active': {
                outline: `1px solid ${palette.colorPrimary}`,
              },
              '&:focus': {
                outline: '2px solid #224fa4',
              },
              '&.Mui-loading': {
                pointerEvents: 'none',
                cursor: 'default',
              },
              '&.Mui-loading .buttonText': {
                color: palette.colorPrimary,
                opacity: 0.1,
              },
              '&.Mui-loading svg': {
                color: palette.colorPrimary,
                opacity: 0.1,
              },
              '& svg': {
                color: palette.colorPrimary,
              },
            },
          },
          {
            props: {variant: 'outlined'},
            style: {
              border: `1px solid ${palette.colorBlack}`,
              color: palette.colorBlack,
              '&:hover': {
                backgroundColor: palette.colorCardGrey,
                border: `1px solid ${palette.colorBlack}`,
              },
              '&.Mui-disabled': {
                opacity: 0.3,
                border: `1px solid ${palette.colorBlack}`,
                color: palette.colorBlack,
              },
              '&:active': {
                backgroundColor: palette.colorCardGrey,
              },
              '&:focus': {
                outline: '2px solid #224fa4',
              },
              '&.Mui-loading': {
                pointerEvents: 'none',
                cursor: 'default',
                backgroundColor: palette.colorCardGrey,
              },
              '&.Mui-loading .buttonText': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
              '&.Mui-loading svg': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
            },
          },
          {
            props: {variant: 'contained'},
            style: {
              backgroundColor: palette.colorPrimary,
              color: palette.colorBlack,
              '&:hover': {
                backgroundColor: '#efdf00',
              },
              '&.Mui-disabled': {
                backgroundColor: '#fff680',
                color: palette.colorBlack,
                '& .buttonText': {
                  opacity: 0.3,
                },
                '& svg': {
                  opacity: 0.3,
                },
              },
              '&:active': {
                outline: '1px solid #000',
              },
              '&:focus': {
                outline: '2px solid #224fa4',
              },
              '&.Mui-loading': {
                pointerEvents: 'none',
                cursor: 'default',
              },
              '&.Mui-loading .buttonText': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
              '&.Mui-loading svg': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
            },
          },
          {
            props: {variant: 'secondary'},
            style: {
              backgroundColor: '#efefef',
              color: palette.colorBlack,
              '&:hover': {
                backgroundColor: palette.colorCardGrey,
              },
              '&.Mui-disabled': {
                backgroundColor: palette.colorBlack,
                color: palette.colorWhite,
                opacity: 0.3,
                '& .buttonText': {
                  opacity: 0.3,
                },
                '& svg': {
                  color: palette.colorWhite,
                  opacity: 0.3,
                },
              },
              '&:active': {
                outline: '1px solid #777777',
                backgroundColor: palette.colorCardGrey,
              },
              '&:focus': {
                outline: '2px solid #224fa4',
              },
              '&.Mui-loading': {
                pointerEvents: 'none',
                cursor: 'default',
              },
              '&.Mui-loading .buttonText': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
              '&.Mui-loading svg': {
                color: palette.colorBlack,
                opacity: 0.1,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontFamily: typography.fontFamilyDefault,
            borderRadius: '2px',
            boxShadow: 'none',
            cursor: 'pointer',
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.5,
            padding: '13px 20px',
            fontWeight: theme.typography.fontWeightBold,
            minWidth: '151px',
            letterSpacing: 'normal',
            overflow: 'hidden',
            textTransform: 'unset',
            '&:hover': {
              boxShadow: 'none',
            },
            '& svg': {
              color: palette.colorBlack,
            },
            '& .MuiButton-endIcon>*:nth-of-type(1)': {
              width: '24px',
              height: '24px',
              margin: 0,
            },
            '& .MuiButton-startIcon>*:nth-of-type(1)': {
              width: '24px',
              height: '24px',
              margin: 0,
            },
            '& .MuiButton-endIcon': {
              marginLeft: '6px',
            },
            '& .MuiButton-startIcon': {
              marginRight: '6px',
            },
            '& .MuiLoadingButton-loadingIndicator': {
              height: '80%',
            },
            '&.MuiButton-sizeSmall': {
              height: '40px',
              fontSize: theme.typography.pxToRem(14),
              '& .MuiButton-endIcon': {
                marginLeft: '6px',
              },
              '& .MuiButton-endIcon>*:nth-of-type(1)': {
                width: '24px',
                height: '24px',
                margin: 0,
              },
              '& .MuiButton-startIcon': {
                marginRight: '6px',
              },
              '& .MuiButton-startIcon>*:nth-of-type(1)': {
                width: '24px',
                height: '24px',
                margin: 0,
              },
            },
            '&.MuiButton-sizeLarge': {
              fontSize: theme.typography.pxToRem(22),
              lineHeight: 1.4,
              padding: '12px 40px 10px',
            },
          },
        },
        defaultProps: {
          variant: 'contained',
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            height: '50px',
          },
        },
      },
    },
  };
};

export default muiButtonTheme;
