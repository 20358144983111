import clsx from 'clsx';
import {Trans, useTranslation} from 'next-i18next';

import {Box, Typography} from '@mui/material';

import {CustomIcon} from '../CustomIcon/CustomIcon';
import styles from './styles.module.scss';
import { palette } from 'styles/mui/scss';

export const ModalHeader = ({
  isWidget,
  hidden,
  isFirstSelection,
  isSelecting,
  isDesktop,
  closeModal,
}: {
  isWidget: boolean;
  isFirstSelection: boolean;
  isSelecting: boolean;
  hidden?: boolean;
  isDesktop: boolean;
  closeModal: () => void;
}) => {
  const [t] = useTranslation('bpr');
  return (
    <Box className={clsx(styles.modalHeader, {[styles.hidden]: hidden})}>
      <Box className={clsx(styles.infoText, {[styles.isWidget]: isWidget})}>
        <Typography>
          <b>
            <Trans t={t} i18nKey="bprDateInfoText" />
          </b>
        </Typography>
        <Box
          sx={{marginTop: 'min(20px, 2vh)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          <CustomIcon
            name="info"
            style={{height: '24px', width: '24px', marginRight: '15px', flexShrink: 0}}
          />
          <Typography color={!isSelecting && !isFirstSelection ? palette.colorMidGrey : undefined}>
            <Trans
              t={t}
              i18nKey="bprDateInfoTextSelect"
              components={[
                <b key="">
                  {!isSelecting ? t('rentalStartPlaceholder') : t('rentalEndPlaceholder')}
                </b>,
              ]}
            />
          </Typography>
        </Box>
      </Box>

      {isWidget || !isDesktop ? (
        <button type="button" className={styles.closeButton}>
          <CustomIcon
            onClick={closeModal}
            name="close"
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        </button>
      ) : null}
    </Box>
  );
};
